<template>
  <div class="room" :style="{ '--height': height + 'px' }" id="chatMessage">
    <div class="room_list" :class="item.userid == userInfoId ? 'flex_direction' : ''"
      v-for="item, index in messageLists" :key="index" @click="listClick(item)">
      <div class="list_left">
        <img :src="item.userimg" alt="">
      </div>
      <div class="list_right">
        <div class="right_top">
          <div class="userphone">{{ item.userphone }}</div>
        </div>
        <div class="right_desc">
          <div class="expect">{{ item?.lottery?.expect }}{{ $t('Cycle') }}</div>
          <div class="money">{{ item?.lottery.totalMoney }}{{ verConfig.moneyType }}</div>
          <div class="code">{{ item?.lottery?.code_lang.join(',') }}</div>
        </div>
      </div>
    </div>
    <Dialog v-model="showList" :show-confirm-button="false" class="dialog">
      <template #title>
        <div class="dialog_top">
          <div class="title">{{ $t('areFollow') }}</div>
          <div class="close">
            <img src="@/assets/icon/clo.png" alt="" @click="close">
          </div>
        </div>
        <div class="dialog_content">
          <div class="cont_list">
            <div class="label">{{ $t('player') }}</div>
            <div class="value">{{ selectList?.userphone }}</div>
          </div>
          <div class="cont_list">
            <div class="label">{{ $t('peroid') }}</div>
            <div class="value">{{ selectList?.lottery.expect }}</div>
          </div>
          <div class="cont_lists list_title">
            <div class="label">{{ $t('category') }}</div>
            <div class="value">{{ $t('label.amount') }}</div>
          </div>
          <div class="cont_lists">
            <div class="label">
              <template v-for="item, index in selectList?.lottery?.code_lang && selectList?.lottery?.code_lang">
                <div class="item_code">
                  <div>{{ item }}</div>
                  <span class="">,</span>
                </div>
              </template>
            </div>
            <div class="value">{{ selectList?.lottery?.totalMoney }}{{ verConfig.moneyType }}</div>
          </div>
          <div class="btns">
            <div class="confirm" @click="confirm">{{ $t('btn.confirm') }}</div>
            <div class="cancel" @click="close">{{ $t('btn.cancel') }}</div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { historyMessage } from "@/api"
import verConfig from "@/utils/verConfig";
import { Popup, Dialog, Icon } from "vant"
export default {
  components: {
    Popup,
    Dialog: Dialog.Component,
    [Icon.name]: Icon
  },
  props: {
    lotteryType: {
      type: String,
      default: ''
    },
    userInfoId: {
      type: String | Number,
      default: ''
    },
    height: {
      type: String | Number,
      default: 0
    }
  },
  data() {
    return {
      messageLists: [],
      timer: null,
      roomid: 0,
      showList: false,
      selectList: {},
      nextShow: true
    }
  },
  watch: {
    listlength(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    }
  },
  computed: {
    listlength() {
      return this.messageLists.length || 0
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  created() {
    this.clear();
    this.nextShow = true
    this.getHistoryMessage();
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.clear();
        this.nextShow = true;
        this.$nextTick(()=>{
          this.getHistoryMessage();
        })
      }
    },
    confirm() {
      this.$emit('select', this.selectList);
    },
    close() {
      this.showList = false
    },
    listClick(item) {
      this.showList = true;
      this.selectList = item
    },
    scrollToBottom() {
      let div = document.getElementById('chatMessage');
      div.scrollTop = div.scrollHeight
    },
    async getHistoryMessage() {
      try {
        const res = await historyMessage({ type: this.lotteryType, id: this.roomid })
        if (this.roomid == 0) {
          this.messageLists = res.data || [];
        } else {
          // this.messageLists = [...this.messageLists, ...(res.data || [])]
          res.data.forEach(item2 => {
            const exists = this.messageLists.some(item1 => item1.id === item2.id);
            if (!exists) {
              this.messageLists.push(item2);
            }
          });
        }
        let ids = [];
        this.messageLists && this.messageLists.forEach(item => {
          ids.push(item.id)
        })
        this.roomid = Math.max(...ids) == '-Infinity' ? 0 : (Math.max(...ids) || 0);
        if (this.nextShow) {
          this.timer = setTimeout(() => {
            this.getHistoryMessage()
          }, 5000)
        }
      } catch (error) { }
    },
    clear() {
      this.$nextTick(() => {
        clearTimeout(this.timer)
        this.timer = null;
      })
    },
    setList() {
      this.clear();
      this.roomid = 0;
      this.messageLists = []
    }
  },
  beforeDestroy() {
    this.nextShow = false
    this.clear();
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
  }
}
</script>
<style lang="less" scoped>
.room {
  height: calc(var(--height) - (51rem / 16));
  overflow-y: scroll;

  &_list {
    padding: calc(10rem / 16) calc(20rem / 16);
    display: flex;
    align-items: center;
    column-gap: calc(10rem / 16);

    .list_left {
      img {
        width: calc(46rem / 16);
        height: calc(46rem / 16);
        display: block;
        border-radius: 50%;
      }
    }

    .list_right {
      flex: 1;

      .right_top {
        font-size: calc(13rem / 16);
        color: var(--textColor);
      }

      .right_desc {
        background-color: #eed57b;
        padding: calc(5rem / 16) calc(10rem / 16);
        border-radius: calc(10rem / 16);
        flex: 1;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        column-gap: calc(10rem / 16);
        flex-wrap: wrap;

        &::after {
          position: absolute;
          top: calc(10rem / 16);
          left: calc(-10rem / 16);
          background-color: var(--light);
          content: ' ';
          width: 0;
          height: 0;
          border-top: 1px solid transparent;
          border-right: calc(10rem / 16) solid #eed57b;
          border-bottom: calc(10rem / 16) solid transparent;
        }

        .expect {
          height: calc(30rem / 16);
          line-height: calc(30rem / 16);
          font-size: calc(12rem / 16);
          color: #69493A;
        }

        .money {
          height: calc(30rem / 16);
          line-height: calc(30rem / 16);
          font-size: calc(20rem / 16);
          color: #69493A;
        }

        .code {
          height: calc(30rem / 16);
          line-height: calc(30rem / 16);
          font-size: calc(20rem / 16);
          color: #69493A;
        }
      }
    }
  }

  .flex_direction {
    flex-direction: row-reverse;

    .list_right {
      .right_top {
        .userphone {
          text-align: right;
        }
      }

      .right_desc {
        &::after {
          left: unset;
          right: calc(-9rem / 16);
          transform: rotateY(180deg);
        }
      }
    }
  }

  .dialog {
    border-radius: calc(4rem / 16);

    /deep/ .van-dialog__header {
      padding: 0;
    }

    .dialog_top {
      height: calc(48rem / 16);
      line-height: calc(48rem / 16);
      padding: 0 calc(20rem / 16);
      box-shadow: 0 .05rem .1rem rgba(63, 55, 52, 0.1);
      background-color: #fdfbfa;
      position: relative;
      display: flex;
      align-items: center;

      .title {
        font-size: calc(17rem / 16);
        color: var(--red);
      }

      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: calc(50rem / 16);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        img {
          width: calc(24rem / 16);
          height: calc(24rem / 16);
        }
      }
    }

    .dialog_content {
      padding: calc(16rem / 16);

      .cont_list {
        height: calc(45rem / 16);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 calc(11rem / 16);
        border-bottom: 1px #ececec solid;
        font-size: calc(15rem / 16);

        .label {
          color: var(--gray1);
        }

        .value {
          color: #655754;
        }
      }

      .cont_lists {
        display: flex;
        padding: calc(6rem / 16) 0;
        column-gap: calc(16rem / 16);
        color: var(--gray1);

        .label {
          width: calc((100% - 16rem / 16) / 2);
          text-wrap: wrap;
          text-align: center;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .item_code {
            display: flex;
            flex-wrap: nowrap;

            &:last-child {
              span {
                display: none;
              }
            }
          }
        }

        .value {
          width: calc((100% - 16rem / 16) / 2);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .list_title {
        border-bottom: 1px #ececec solid;
        height: calc(45rem / 16);
        line-height: calc(45rem / 16);
        color: #655754;
      }

      .btns {
        padding-top: calc(16rem / 16);

        .confirm {
          margin-bottom: calc(10rem / 16);
          display: block;
          height: calc(40rem / 16);
          line-height: calc(40rem / 16);
          font-size: calc(17rem / 16);
          color: var(--light);
          text-align: center;
          background: var(--red);
          background-image: -webkit-gradient(linear, 0% 0, 0% 100%, from(#e54d4d), to(#cf2c2c));
          border-radius: calc(4rem /16);
          border: 0;
          cursor: pointer;
          border: 0;
          width: 100%;
          box-shadow: 0 .05rem .1rem rgba(0, 0, 0, 0.1);
        }

        .cancel {
          .confirm();
          border: 1px #d3beb6 solid;
          background: #e8e0dd;
          background-image: -webkit-gradient(linear, 0% 0, 0% 100%, from(#eee2dd), to(#e2d3cd));
          color: #4c4743;
        }
      }
    }
  }
}
</style>