<template>
  <div class="content_wrap">
    <div class="wraps" v-for="val, num in 5" :key="num">
      <div class="title">{{ $t(`game.fiveBall.${num}`) }}</div>
      <div class="select_wrap">
        <template v-for=" index in 11">
          <div class="select_item" @click="select(index, num)"
            :class="selectArr[num]?.includes(index < 10 ? '0' + index : index) ? 'active' : ''">
            <p>{{ index < 10 ? '0' + index : index }}</p>
                <p>{{ x5Result.dxds && x5Result.dwd.odds || 0 }}</p>
          </div>
        </template>
      </div>
      <div class="game_btns">
        <div class="btn_item" v-for="item, index in gameBtns" :key="index" @click="btnClick(item, num)">
          {{ $t(`game.${item}`) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    x5Result: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      gameBtns: ['whole', 'large', 'small', 'odd', 'even', 'clear'],
      selectArr: new Array(5).fill('')
    }
  },
  computed: {

  },
  methods: {
    select(index, num) {
      const newIndex = index < 10 ? '0' + index : index;
      let currentSelection = this.selectArr[num];
      if (currentSelection.includes(newIndex)) {
        this.$set(this.selectArr, num, currentSelection.replace(newIndex + ',', ''));
      } else {
        this.$set(this.selectArr, num, currentSelection + newIndex + ',');
      }
      this.$emit('submit', this.selectArr)
    },
    clear() {
      this.selectArr = new Array(5).fill('')
    },
    btnClick(item, num) {
      const game = {
        whole: '01,02,03,04,05,06,07,08,09,10,11,',
        large: '06,07,08,09,10,11,',
        small: '01,02,03,04,05,',
        odd: '01,03,05,07,09,11,',
        even: '02,04,06,08,10,'
      }
      if (item == 'clear') {
        this.$set(this.selectArr, num, '')
      } else {
        this.$set(this.selectArr, num, game[item]);
      }
      this.$emit('submit', this.selectArr)
    },
  }
}
</script>
<style lang="less" scoped>
.content_wrap {
  padding: 0 calc(20rem / 16);
  padding-bottom: calc(136rem / 16);

  .wraps {
    .title {
      white-space: nowrap;
      text-align: center;
      color: #999;
    }
  }

  .select_wrap {
    margin: calc(15rem / 16) 0;
    display: flex;
    flex-wrap: wrap;
    gap: calc(5rem / 16);
    justify-content: center;

    .select_item {
      @height: calc(46rem / 16);
      border-radius: 4px;
      background: var(--light);
      font-size: calc(18rem / 16);
      width: calc((100% - (20rem / 16)) / 5);
      display: flex;
      align-items: center;
      flex-direction: column;
      color: var(--textColor);
      padding: calc(10rem / 16) 0;

      >p {
        &:nth-last-child(1) {
          font-size: calc(10rem / 16);
          word-break: break-all;
          text-overflow: ellipsis;
          margin-top: calc(3rem / 16);
          color: var(--gray1);
        }
      }
    }

    .active {
      background-color: var(--red);
      color: var(--light);
    }
  }

  .game_btns {
    display: flex;
    justify-content: space-between;
    column-gap: calc(5rem / 16);
    padding-bottom: calc(12rem / 16);

    .btn_item {
      padding: calc(6rem / 16) calc(8rem / 16);
      background: #404b5e;
      font-size: calc(14rem / 16);
      color: #ccc;
      border-radius: calc(2rem / 16);
    }
  }
}
</style>