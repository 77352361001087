<template>
  <div class="content_wrap">
    <div class="wraps" v-for="val, num in 10" :key="num">
      <div class="title">{{ $t(`game.pk_${num}`) }}</div>
      <div class="select_wrap">
        <template v-for="(item, index) in selectTypes">
          <div class="select_item" v-if="item.index > num" :class="selectArr[num].includes(item.value) ? 'active' : ''"
            @click="select(item, num, index)">
            <p>{{ item.title }}</p>
            <p>{{ pkResult[item.methods] && pkResult[item.methods].odds || 0 }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pkResult: {
      type: Object,
      default: []
    }
  },
  data() {
    return {
      selectArr: ['', '', '', '', '', '', '', '', '', ''],
      selectTypes: [
        { title: this.$t("open.big"), value: "大", methods: 'dxds', index: 10 },
        { title: this.$t("open.small"), value: "小", methods: 'dxds', index: 10 },
        { title: this.$t("open.single"), value: "单", methods: 'dxds', index: 10 },
        { title: this.$t("open.double"), value: "双", methods: 'dxds', index: 10 },
        { title: this.$t("open.loong"), value: "龙", methods: 'lh', index: 3 },
        { title: this.$t("open.hu"), value: "虎", methods: 'lh', index: 3 },
      ],
    }
  },
  computed: {
    gameArr() {
      let arr = [];
      for (let i = 0; i < 10; i++) {
        let obj = {}
        obj.title = `game.pk_${i}`;
        obj.selectTypes = this.selectTypes;
        arr.push(obj)
      }
      return arr
    }
  },
  methods: {
    select(item, num, index) {
      let currentSelection = this.selectArr[num];
      if (currentSelection.includes(item.value)) {
        this.$set(this.selectArr, num, currentSelection.replace(item.value, ''));
      } else {
        this.$set(this.selectArr, num, currentSelection + item.value);
      }
      this.$emit('submit', this.selectArr);
    },
    clear(){
      this.selectArr = ['', '', '', '', '', '', '', '', '', '']
    }
  }
}
</script>
<style lang="less" scoped>
.content_wrap {
  padding: 0 calc(20rem / 16);
  padding-bottom: calc(136rem / 16);

  .wraps {
    .title {
      white-space: nowrap;
      text-align: center;
      color: #999;
    }
  }

  .select_wrap {
    margin: calc(15rem / 16) 0;
    display: flex;
    flex-wrap: wrap;
    gap: calc(10rem / 16);
    justify-content: center;

    .select_item {
      @height: calc(46rem / 16);
      border-radius: 4px;
      background: var(--light);
      font-size: calc(18rem / 16);
      width: calc((100% - (20rem / 16)) / 3);
      display: flex;
      align-items: center;
      flex-direction: column;
      color: var(--textColor);
      padding: calc(10rem / 16) 0;

      >p {
        &:nth-last-child(1) {
          font-size: calc(10rem / 16);
          word-break: break-all;
          text-overflow: ellipsis;
          margin-top: calc(3rem / 16);
          color: var(--gray1);
        }
      }
    }

    .active {
      background-color: #f60;
      color: var(--light);
    }
  }
}
</style>