select<template>
  <div class="wrap">
    <Navbar :title="configs.title || ''">
      <template #right>
        <i class="iconfont nav_tools_item" v-for="(item, index) in navTools" :key="`tool_${index}`" :class="item.icon"
          @click="item.click"></i>
      </template>
    </Navbar>
    <div class="">
      <TxffcTop v-if="category != 'k3' && category != 'dd'" :timeCount="countText" :currentConfig="currentConfigs"
        :lastConfig="lastConfigs" :type="type">
        <template #money>
          <div class="banlace">{{ $t('reportCenter.balance') }}:{{ userInfoMoney }}</div>
        </template>
      </TxffcTop>
      <template v-else>
        <div class="top_wrap">
          <div class="right">
            <div class="text">
              <span>{{ orderLists[1] && orderLists[1].expect }}</span>
              {{ $t("game.stage") }}
            </div>
            <template v-if="orderLists[1] || orderLists[0]">
              <!-- <div class="status" v-if="category == 'k3'">
                <img v-for="(item, index) in (orderLists[1] || orderLists[0]).open.dwd" :key="index"
                  :src="require(`@/assets/icon/dice/${item}.png`)" alt="" />
              </div> -->
              <div class="status txffc">
                <div class="ball_wrap">
                  <div class="ball" v-for="(item, index) in (orderLists[1] || orderLists[0]).open
                    .dwd" :key="index">
                    {{ item }}
                  </div>
                </div>
                <div class="sum_ball">
                  <span class="label">{{ $t("game.typeHz") }}</span>
                  <span class="sum">{{
                    (orderLists[1] || orderLists[0]).open['hz-hz-dxds']?.hz
                  }}</span>
                  <span class="result">{{
                    `${getStatusText(
                      (orderLists[1] || orderLists[0]).open['hz-hz-dxds']?.dx
                    ) || ''} / ${getStatusText(
                      (orderLists[1] || orderLists[0]).open['hz-hz-dxds']?.ds
                    ) || ''}`
                  }}</span>
                </div>
              </div>
            </template>
          </div>
          <div class="left">
            <div class="text">
              <span>{{ orderLists[0] && orderLists[0].expect }}</span>
              {{ $t("game.stage") }}
            </div>
            <div class="time">{{ countText }}</div>
            <div class="banlace">{{ $t('reportCenter.balance') }}:{{ userInfoMoney }}</div>
          </div>
        </div>
        <div class="top_icon">
          <van-icon name="arrow-down" color="var(--textColor)" size="16"
            @click="$router.push('/GameRecord?type=' + type)" />
        </div>
        <div class="game_desc">{{ $t('game.k3') }}</div>
      </template>
    </div>
    <div class="room" ref="targetElement">
      <Room :height="Height" :lotteryType="type" :userInfoId="userInfo.id" ref="roomRef" @select="roomSend" />
      <div class="room_btn">
        <div class="btn" style="background-color: var(--red);" @click="gameShow = true">{{ $t('purchaseResources') }}
        </div>
      </div>
    </div>
    <van-popup ref="popRef" v-model="gameShow" position="bottom" :overlay="false" close-on-popstate
      :close-on-click-overlay="false" class="game_pop" :style="{ '--height': Height + 'px' }">
      <!-- category==pk -->
      <template v-if="category == 'pk'">
        <PK :pkResult="pkResult" @submit="pkSubmit" ref="pkRef" />
      </template>
      <!-- category==dd -->
      <!-- <template v-else-if="category == 'dd'">
        <div class="content_wrap">
          <div class="newGame">
            <div class="gameLists" v-for="index in 3" :key="index">
              <div class="list_title">{{ $t('list_tableNumber' + index) }}</div>
              <div class="list">
                <div class="list_value" :class="newDxdsCode[index - 1].includes(value.value) ? 'list_active' : ''"
                  v-for="value, num in newselectTypes" :key="num" @click="newDxdsClick(value, index, num)">
                  <div class="title">{{ value.title }}</div>
                  <div class="value">{{ newResultDxds['dxds']?.odds }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template> -->
      <div class="content_wrap" v-else-if="category == 'dd'">
        <div class="select_wrap">
          <div class="select_wrap_left">
            <div class="lable_item" :class="activeLabel == index ? 'active' : ''" v-for="item, index in labels"
              :key="index" @click="activeLabel = index">
              {{ $t(item) }}
            </div>
          </div>
          <div class="select_wrap_right">
            <div class="label_right_title">{{ $t(labels[activeLabel]) }}</div>
            <div class="rights" v-if="activeLabel == 0">
              <div class="select_item" v-for="(item, index) in selectTypes" :key="index"
                :class="!!selectArr.find((a) => a == item.value) && 'active'" @click="select(item)">
                <p>{{ item.title }}</p>
                <p>{{ (dxdsArr[index] && dxdsArr[index]?.odds) || 0 }}</p>
              </div>
            </div>
            <div class="rights special_code" v-else>
              <div class="select_item" v-for="(item, index) in games" :key="`num_${index}`"
                :class="!!numSelectArr.find((a) => a == item.title) && 'active'" @click="numSelect(item)">
                <p><span class="number">{{ item.title }}</span></p>
                <p>{{ item.odds || 0 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- category==x5 -->
      <template v-else-if="category == 'x5'">
        <X5 :x5Result="x5Result" @submit="x5Submit" ref="x5Ref" />
      </template>
      <!-- category==k3 -->
      <div class="content_wrap" v-else-if="category == 'k3'">
        <div class="select_wrap">
          <div class="select_wrap_left">
            <div class="lable_item" :class="activeLabel == index ? 'active' : ''" v-for="item, index in labels"
              :key="index" @click="activeLabel = index">
              {{ $t(item) }}
            </div>
          </div>
          <div class="select_wrap_right">
            <div class="label_right_title">{{ $t(labels[activeLabel]) }}</div>
            <div class="rights" v-if="activeLabel == 0">
              <div class="select_item" v-for="(item, index) in selectTypes" :key="index"
                :class="!!selectArr.find((a) => a == item.value) && 'active'" @click="select(item)">
                <p>{{ item.title }}</p>
                <p>{{ (dxdsArr[index] && dxdsArr[index]?.odds) || 0 }}</p>
              </div>
            </div>
            <div class="rights" v-else>
              <div class="select_item" v-for="(item, index) in games" :key="`num_${index}`"
                :class="!!numSelectArr.find((a) => a == item.title) && 'active'" @click="numSelect(item)">
                <p><span class="number">{{ item.title }}</span></p>
                <p>{{ item.odds || 0 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_wrap" v-else>
        <div class="newGame">
          <div class="gameLists" v-for="index in 5" :key="index">
            <div class="list_title">{{ $t('list_tableNumber' + index) }}</div>
            <div class="list">
              <div class="list_value" :class="newDxdsCode[index - 1].includes(value.value) ? 'list_active' : ''"
                v-for="value, num in newselectTypes" :key="num" @click="newDxdsClick(value, index, num)">
                <div class="title">{{ value.title }}</div>
                <div class="value">{{ newResultDxds['dxds']?.odds }}</div>
              </div>
            </div>
          </div>
          <div class="all_title" v-if="dxdsArr.length">{{ $t('list_tableAll') }}</div>
        </div>
        <div class="select_wrap" :style="{ marginTop: 0, justifyContent: 'center' }" v-if="dxdsArr.length">
          <div class="select_item" v-for="(item, index) in selectTypes" :key="index"
            :class="!!selectArr.find((a) => a == item.value) && 'active'" @click="select(item)">
            <p>{{ item.title }}</p>
            <p>{{ (dxdsArr[index] && dxdsArr[index]?.odds) || 0 }}</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer_pay" v-if="isShowFooter || showInput || getNewDxdsLength()">
          <div class="footer_pay_title">{{ $t('quickOrder') }}</div>
          <div class="footer_pay_num">
            <div class="nums" v-for="item, index in moneyNum" :key="index" @click="perPrice = item">{{ item }}</div>
          </div>
          <div class="footer_pay_money">
            <div class="money_title">{{ $t('purchasingPrice') }}: </div>
            <div class="money_input">
              <!-- <input v-model="perPrice" type="number" /> -->
              <van-field class="input" v-model="perPrice" type="number" />
            </div>
            <div class="buy" @click="toConfirmMatch">{{ $t('buy') }}</div>
            <div class="reset" @click="reset">{{ $t('reset') }}</div>
          </div>
          <div class="title">{{ $t('chosenPlay', { num: matchAmount + getNewDxdsLength() }) }}</div>
          <div class="how_play" @click="ruleContent && (showRule = true)">{{ $t('howToPlay') }}</div>
        </div>
        <!-- <div class="input_wrap" v-if="isShowFooter || showInput || getNewDxdsLength()">
          <div class="left">
            <span>{{
              `${$t("game.perPrice.text")} ${verConfig.moneyType}:`
            }}</span>
            <input v-model="perPrice" type="number" :placeholder="$t('input.permoney.placeholder')" />
          </div>
          <div class="clear" @click="clear">
            <div class="icon">
              <van-icon name="delete-o" color="var(--red)" size="20" />
            </div>
            <div class="text">{{ $t("btn.clear") }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="right">
            <span>{{ `${$t("balance.text")}:` }}</span>
            <span class="price">{{ userInfo.money || "0.00" }}</span>
          </div>
          <div class="buy" @click="toConfirmMatch">{{ $t("btn.match") }}</div>
        </div> -->
      </div>
      <div class="room_btn">
        <div class="btn" @click="gameShow = false; clear()">{{ $t('return') }}</div>
      </div>
      <div class="overlays" v-if="countTime == 0 || countTime < 0"></div>
    </van-popup>
    <div class="right_popup_wrap">
      <van-popup v-model="rightShow" position="top">
        <div class="tool_wrap">
          <div class="tool_item" v-for="(item, index) in gameArr" :key="index"
            :style="{ borderBottom: index === gameArr.length - 1 ? '0' : '' }" @click="toolClick(item)">
            {{ item.title }}
          </div>
        </div>
      </van-popup>
    </div>
    <van-popup class="buy_pop" v-model="buyShow" closeable close-icon-position="top-left" position="bottom">
      <div class="titles">{{ $t("popup.title.buyConfirm") }}</div>
      <div class="content">
        <div class="li">
          <div class="left">{{ configs.title }}:</div>
          <div class="right">
            {{
              (orderLists[0] && orderLists[0].expect) ||
              "" + $t("popup.text.data")
            }}
          </div>
        </div>
        <div class="li">
          <div class="left">{{ $t("popup.title.matchAmount") }}:</div>
          <div class="right" style="color: #ff0000">
            <template v-if="category == 'pk'">
              {{ $money(pkHlDxdsNum * (this.perPrice || 0), false) }}
            </template>
            <template v-if="category == 'x5'">
              {{ $money(x5dxdsNum * (this.perPrice || 0), false) }}
            </template>
            <template v-else>
              {{ $money((matchAmount + getNewDxdsLength()) * (this.perPrice || 0), false) }}
            </template>
          </div>
        </div>
        <template v-if="category == 'pk'">
          <div class="li">
            <div class="left">{{ $t("popup.title.matchContent") }}:</div>
            <div class="right rights">
              <template v-for="(item, index) in PkArr">
                <span v-if="item != ''">
                  <div class="right_new" v-if="getStatusTexts(item).length > 0">
                    <span class="number">{{ index + 1 }}</span>&nbsp;
                    <div class="new_list" v-for="(value, num) in getStatusTexts(item)">
                      <span class="value">{{ getStatusText(value) }}</span>
                      <span class="icon">,</span>
                    </div>
                  </div>
                </span>
              </template>
            </div>
          </div>
        </template>
        <template v-else-if="category == 'x5'">
          <div class="li">
            <div class="left">{{ $t("popup.title.matchContent") }}:</div>
            <div class="right">
              <template v-for="(item, index) in X5Arr">
                <div v-if="item != ''">
                  <div class="right_new" v-if="getStatusTexts(item).length > 0">
                    <span class="number">{{ index + 1 }}</span>&nbsp;
                    <div class="new_list">
                      <span class="value">{{ item.replace(/(.{2})/g, '$1,').slice(0, -1) }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <div class="li" v-else-if="category == 'k3'">
          <div class="left">{{ $t("popup.title.matchContent") }}:</div>
          <div class="right">
            <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in [...selectArr, ...numSelectArr]"
              :key="index">
              {{ getStatusText(item) }}&nbsp;
            </span>
          </div>
        </div>
        <!-- v-else-if="category != 'k3'" -->
        <template v-else>
          <div class="title">{{ $t("popup.title.matchContent") }}:</div>
          <div class="li" v-for="(item, index) in txffcGames" :key="`content_${index}`"
            v-show="(txffcSelectData[index] || []).length">
            <div class="left">{{ $t(`game.fiveBall.${index}`) }}:</div>
            <div class="right">
              <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in txffcSelectData[index] || []"
                :key="index">
                {{ getStatusText(item) }}&nbsp;
              </span>
            </div>
          </div>
          <div class="li" v-if="selectArr.length">
            <div class="left">{{ $t("game.typeHz") }}:</div>
            <div class="right">
              <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in [...selectArr]" :key="index">
                {{ getStatusText(item) }}&nbsp;
              </span>
            </div>
          </div>
          <div class="li" v-if="getNewDxdsLength()">
            <div class="left">{{ $t("game.typeHz") }}:</div>
            <div class="right">
              <span v-for="(item, index) in newDxdsCode" :key="index">
                <div class="right_new" v-if="getStatusTexts(item).length > 0">
                  <span class="number">{{ index + 1 }}</span>&nbsp;
                  <div class="new_list" v-for="(value, num) in getStatusTexts(item)">
                    <span class="value">{{ getStatusText(value) }}</span>
                    <span class="icon">,</span>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </template>
      </div>
      <div class="hint">
        {{ $t("hint.confirmBuy") }}
      </div>
      <div class="btn_wrap">
        <div class="btn" @click="toMatch">{{ $t("popup.btn.match") }}</div>
      </div>

    </van-popup>
    <van-popup class="buy_pop" v-model="orderShow" closeable position="bottom" :style="{ maxHeight: '50vh' }">
      <div class="sticky">
        <div class="title">
          {{ $t("popup.title.matchResult") }}
          <div class="icon">
            <van-icon @click="orderShow = false" name="cross" size="22" color="#c8c9cc" />
          </div>
        </div>
        <div class="title_bar">
          <div class="li">{{ $t("popup.orderId") }}</div>
          <div class="li">{{ $t("open.result") }}</div>
        </div>
      </div>
      <div class="list_wrap">
        <div class="li_wrap" v-for="(item, index) in orderLists.filter(
          (a) => Object.keys(a.open).length > 0
        )" :key="index">
          <div class="li">{{ item.expect }}</div>
          <div class="li">
            <template v-if="type.indexOf('hl') !== -1">
              <img v-for="(r_item, r_index) in item.open.dwd" :key="'result' + index + r_index"
                :src="require(`@/assets/icon/dice/${r_item}.png`)" alt="" />
            </template>
          </div>
        </div>
      </div>
    </van-popup>
    <Dialog v-model="showRule" :title="$t('game.rule.title')" show-cancel-button :show-confirm-button="false"
      :cancel-button-text="$t('btn.cancel')">
      <div class="dialog_cont" v-html="ruleContent"></div>
    </Dialog>
  </div>
</template>

<script>
import { router } from '@/router'
import { $post, $get } from "@/utils/request";
import { post } from "@/utils/newRequest";
import Navbar from "@/components/JNav";
import TxffcTop from "./components/txffcTop.vue";
import PK from "./components/pk.vue";
import X5 from "./components/x5.vue"
import Room from './components/room.vue'
import {
  NavBar,
  cell,
  icon,
  Field,
  Dialog,
  Toast,
  Tab,
  Tabs,
  Popup,
} from "vant";

export default {
  components: {
    [NavBar.name]: NavBar,
    [icon.name]: icon,
    [Popup.name]: Popup,
    Toast,
    Navbar,
    Dialog: Dialog.Component,
    TxffcTop,
    PK,
    X5,
    Room,
    [Field.name]:Field
  },
  data() {
    this.navTools = [
      // {
      //   icon: "icon-f4f5f8",
      //   title: "rule",
      //   click: () => {
      //     this.showRule = true;
      //   },
      // },
      {
        icon: "icon-dingdan1",
        title: "record",
        click: () => {
          this.toRecord();
        },
      },
      {
        icon: "icon-gengduo",
        title: "more",
        click: () => {
          this.rightShow = !this.rightShow;
        },
      },
    ];
    this.selectTypes = [
      { title: this.$t("open.big"), value: "大" },
      { title: this.$t("open.small"), value: "小" },
      { title: this.$t("open.single"), value: "单" },
      { title: this.$t("open.double"), value: "双" },
    ];
    this.newselectTypes = [
      { title: this.$t("open.big"), value: "大", methods: 'hz-hz-big' },
      { title: this.$t("open.small"), value: "小", methods: 'hz-hz-small' },
      { title: this.$t("open.single"), value: "单", methods: 'hz-hz-single' },
      { title: this.$t("open.double"), value: "双", methods: 'hz-hz-dual' },
    ];
    return {
      configs: {},
      rightShow: false,
      orderLists: [],
      selectArr: [],
      perPrice: "",
      userInfo: {},
      interval: null,
      countText: "00:00:00",
      countTime: 0,
      buyShow: false,
      orderShow: false,
      games: [],
      dxdsConfig: {},
      numSelectArr: [],
      ruleContent: "",
      showRule: false,
      txffcGames: [],
      txffcSelectData: {},
      currentGame: {},
      dxdsArr: [],
      isFindIndex: '',
      newDxdsArr: [],
      newResultDxds: {},
      newDxdsCode: ['', '', '', '', ''],
      timer: null,
      type: "",
      pkResult: {},
      isShowFooter: false,
      PkArr: [],
      addTime: null,
      pkHlDxdsArr: [new Array(10).fill(''), new Array(3).fill('')],
      lastCurrent: {},
      num: -1,
      timerss: null,
      x5Result: {},
      X5Arr: [],
      x5dxdsNum: 0,
      gameShow: false,
      Height: '',
      roomList: {},
      labels: ['Mixed', 'specialCode'],
      activeLabel: 0,
      moneyNum: [10, 50, 100, 200, 500, 1000],
      activeMoney: '',
      userInfoMoney: 0
    };
  },
  created() {
    const { type } = this.$route.query;
    this.type = type;
    this.stopTimer();
    this.$nextTick(() => {
      this.getRoomData();
      this.getOrders();
    })
    this.getUserInfo();
  },
  mounted() {
    this.scrollToBottom();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  computed: {
    // type() {
    //   const { type } = this.$route.query;
    //   return type;
    // },
    category() {
      const { category } = this.$route.query;
      return category
    },
    merchants() {
      return this.$store.getters.AllGameList
    },
    gameArr() {
      return this.merchants.filter(item => item.category == this.category) || [];
    },
    tools() {
      return [
        // { title: this.$t("tab.record"), fun: () => this.toRecord() },
        { title: this.$t("btn.recharge"), fun: () => this.toRecharge() },
        { title: this.$t("btn.withdraw"), fun: () => this.toWithdraw() },
        // { title: this.$t("btn.service"), fun: () => this.toService() },
      ];

    },
    showInput() {
      let flag = false;
      for (const key in this.txffcSelectData) {
        if (Object.hasOwnProperty.call(this.txffcSelectData, key)) {
          const arr = this.txffcSelectData[key];
          if (arr.length) {
            flag = true;
          }
        }
      }
      return flag || [...this.selectArr, ...this.numSelectArr].length;
    },
    txffcMatchNum() {
      let num = 0;
      for (const key in this.txffcSelectData) {
        if (Object.hasOwnProperty.call(this.txffcSelectData, key)) {
          const item = this.txffcSelectData[key] || [];
          num += item.length;
        }
      }
      return num;
    },
    matchAmount() {
      let num = this.txffcMatchNum;
      num += [...this.selectArr, ...this.numSelectArr].length;
      return num;
    },
    currentConfigs() {
      return this.currentGame;
    },
    lastConfigs() {
      return this.orderLists[1] || this.orderLists[0] || {};
    },
    pkHlDxdsNum() {
      return this.PkArr.reduce((sum, item) => sum + item.length, 0)
    },
  },
  watch: {
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        const { type } = this.$route.query;
        this.type = type;
        this.stopTimer();
        this.$nextTick(() => {
          this.getRoomData();
          this.getOrders();
        })
        this.getUserInfo();
      }
    },
    roomSend(item) {
      this.roomList = item;
      this.$nextTick(() => {
        this.toMatch();
      })
    },
    scrollToBottom() {
      const rect = this.$refs.targetElement && this.$refs.targetElement.getBoundingClientRect();
      this.Height = rect.height - (rect.bottom - window.innerHeight);
    },
    x5Submit(arr) {
      let arrs = []
      const index = arr.filter(obj => obj != '');
      const totalLength = arr.reduce((sum, item) => sum + item.length, 0);
      this.x5dxdsNum = totalLength / 3;
      this.isShowFooter = index.length > 0 ? true : false;
      arr.forEach(item => {
        let obj = item.replaceAll(',', '')
        arrs.push(obj)
      })
      this.X5Arr = arrs;
    },
    pkSubmit(arr) {
      this.PkArr = arr;
      const index = arr.filter(obj => obj != '');
      this.isShowFooter = index.length > 0 ? true : false;
      arr.forEach((item, num) => {
        if (item.includes('龙') || item.includes('虎')) {
          this.pkHlDxdsArr[1][num] = item.split('').filter(char => char === '龙' || char === '虎').join('');
        } else {
          this.pkHlDxdsArr[1][num] = ''
          this.pkHlDxdsArr[1].length = 3
        }
        this.pkHlDxdsArr[0][num] = item.replace(/龙|虎/g, '')
      });
    },
    toolClick(item) {
      this.type = item.type;
      this.clear();
      this.stopTimer();
      this.$refs.roomRef.setList();
      this.$nextTick(() => {
        this.getRoomData();
        this.getOrders();
        this.$refs.roomRef.getHistoryMessage()
      })
      this.rightShow = false;
    },
    getNewDxdsLength() {
      return this.newDxdsCode.reduce((acc, value) => acc + value.length, 0)
    },
    newDxdsClick(item, index, num) {
      if (this.newDxdsCode[index - 1].includes(item.value)) {
        // const str = this.newDxdsCode[index - 1].replace(item.value, '');
        // this.newDxdsCode[index - 1] = str
        this.$set(this.newDxdsCode, index - 1, this.newDxdsCode[index - 1].replace(item.value, ''))
      } else {
        // this.newDxdsCode[index - 1] += item.value
        this.$set(this.newDxdsCode, index - 1, this.newDxdsCode[index - 1] + item.value)
      }
      if (this.category == 'dd') {
        this.newDxdsCode.length = 3
      }
    },
    toRecord() {
      this.$router.push({ name: 'bettingRecord', query: { type: this.type } })
    },
    toRecharge() {
      this.$router.push("/new-recharge");
    },
    toWithdraw() {
      this.$router.push("/new-withdraw");
    },
    // 获取房间信息
    async getRoomData() {
      // this.dxdsArr = [];
      try {
        const res = await $get("/lottery/setting", {
          type: this.type,
          explain: 1,
        });
        const { ret, msg, data } = res.data;
        this.configs = data[0];
        // this.games = [];
        this.ruleContent = this.configs.method_explain;
        // if (this.category == 'pk') {
        //   const newArr = data[0].odds;
        //   this.newDxdsArr = newArr;
        //   const result = {};
        //   for (const item of newArr) {
        //     result[item.method] = { ...item };
        //   }
        //   this.pkResult = result
        //   return
        // }
        // if (this.category != 'k3') {
        //   const newArr = data[0].odds;
        //   this.isFindIndex = newArr.findIndex(item => item.method == 'dxds') >= 0 ? true : false;
        //   this.newDxdsArr = newArr;
        //   const result = {};
        //   for (const item of newArr) {
        //     result[item.method] = { ...item };
        //   }
        //   this.newResultDxds = result;
        // }
        // if (this.category != 'k3') {
        //   const item = data[0].oddtrees[0].items.hz;
        //   for (const key in item) {
        //     if (Object.hasOwnProperty.call(item, key)) {
        //       const value = item[key];
        //       if (value) {
        //         switch (key) {
        //           case 'big':
        //             value.title = "大"
        //             break;
        //           case 'small':
        //             value.title = "小"
        //             break;
        //           case 'single':
        //             value.title = "单"
        //             break;
        //           case 'dual':
        //             value.title = "双"
        //             break;
        //           default:
        //             break;
        //         }
        //         value.method = `hz-hz-${key}`
        //         this.dxdsArr.push(value)
        //       }
        //     }
        //   }
        //   return;
        // }
        // if(this.category == 'k3'){
        //   const games = data[0].oddtrees[0].items.hz;
        //   const dxdsKeys = ['big', 'small', 'single', 'dual'];
        //   const keys = Object.keys(games).filter(item => (item.indexOf('dxds') < 0 && dxdsKeys.indexOf(item) < 0));
        //   for (const key in games) {
        //     if (Object.hasOwnProperty.call(games, key)) {
        //       const value = games[key];
        //       if (value) {
        //         switch (key) {
        //           case 'big':
        //             value.title = "大"
        //             break;
        //           case 'small':
        //             value.title = "小"
        //             break;
        //           case 'single':
        //             value.title = "单"
        //             break;
        //           case 'dual':
        //             value.title = "双"
        //             break;
        //           default:
        //             break;
        //         }
        //         if (dxdsKeys.includes(key)) {
        //           value.method = `hz-hz-${key}`
        //           this.dxdsArr.push(value)
        //         }
        //       }
        //     }
        //   }
        //   for (const key of keys) {
        //     const value = games[key];
        //     value.title = key;
        //     value.method = `hz-hz-${key}`;
        //     this.games.push(value);
        //   }
        // }
      } catch (error) { }
    },
    // 获取订单
    async getOrders(pageSize = 2, flag = false, callback = () => { }) {
      Toast.loading();
      this.dxdsArr = [];
      // this.games = [];
      try {
        const res = await $get("/lottery/lists", {
          type: this.type,
          pagesize: pageSize,
        });
        const { ret, msg, data } = res.data;
        this.userInfoMoney = data.money
        this.orderLists = data.lists;
        const current = data.lists[0];
        this.lastCurrent = data.lists[1];
        if (this.category == 'pk') {
          const newArr = current.odds;
          this.newDxdsArr = newArr;
          const result = {};
          for (const item of newArr) {
            result[item.method] = { ...item };
          }
          this.pkResult = result
        }
        if (this.category == 'x5') {
          const newArr = current.odds;
          const result = {};
          for (const item of newArr) {
            result[item.method] = { ...item };
          }
          this.x5Result = result
        }
        if (this.category != 'k3' && this.category != 'dd') {
          const newArr = current.odds;
          this.isFindIndex = newArr.findIndex(item => item.method == 'dxds') >= 0 ? true : false;
          this.newDxdsArr = newArr;
          const result = {};
          for (const item of newArr) {
            result[item.method] = { ...item };
          }
          this.newResultDxds = result;
        }
        if (this.category != 'k3' && this.category != 'dd') {
          const item = current.odds.find(val => val.method == 'hz-hz-dxds')
          if (item) {
            this.dxdsArr = [{
              "method": "hz-hz-dxds",
              "odds": item.odds,
              "maxnum": 0,
              "title": "大"
            }, {
              "method": "hz-hz-dxds",
              "odds": item.odds,
              "maxnum": 0,
              "title": "小"
            }, {
              "method": "hz-hz-dxds",
              "odds": item.odds,
              "maxnum": 0,
              "title": "单"
            }, {
              "method": "hz-hz-dxds",
              "odds": item.odds,
              "maxnum": 0,
              "title": "双"
            }]
          }
        }
        if (this.category == 'dd') {
          const games = current.odds;
          const dxdsKeys = ['big', 'small', 'single', 'dual'];
          const keys = games.filter(item => (item.method.indexOf('dxds') < 0 && item.method.indexOf('dwd') < 0 && !dxdsKeys.some(key => item.method.includes(key))));
          const item = games.filter(val => dxdsKeys.some(key => val.method.includes(key)))
          if (item) {
            let arr = []
            item.forEach(key => {
              switch (key.method) {
                case 'hz-hz-big':
                  key.title = '大'
                  arr.push(key)
                  break;
                case 'hz-hz-small':
                  key.title = '小'
                  arr.push(key)
                  break;
                case 'hz-hz-single':
                  key.title = '单'
                  arr.push(key)
                  break;
                case 'hz-hz-dual':
                  key.title = '双'
                  arr.push(key)
                  break;
                default:
                  break;
              }
            })
            this.dxdsArr = arr;
          }
          let arr = [];
          keys.forEach(items => {
            items.title = items.method.split('hz-hz-')[1]
            arr.push(items)
            // this.games.push(items)
          })
          this.games = arr;
        }
        if (this.category == 'k3') {
          const games = current.odds;
          const dxdsKeys = ['big', 'small', 'single', 'dual'];
          const keys = games.filter(item => (item.method.indexOf('dxds') < 0 && item.method.indexOf('dwd') < 0 && !dxdsKeys.some(key => item.method.includes(key))));
          const item = games.filter(val => dxdsKeys.some(key => val.method.includes(key)))
          if (item) {
            let arr = []
            item.forEach(key => {
              switch (key.method) {
                case 'hz-hz-big':
                  key.title = '大'
                  arr.push(key)
                  break;
                case 'hz-hz-small':
                  key.title = '小'
                  arr.push(key)
                  break;
                case 'hz-hz-single':
                  key.title = '单'
                  arr.push(key)
                  break;
                case 'hz-hz-dual':
                  key.title = '双'
                  arr.push(key)
                  break;
                default:
                  break;
              }
            })
            this.dxdsArr = arr;
          }
          let arr = [];
          keys.forEach(items => {
            items.title = items.method.split('hz-hz-')[1]
            arr.push(items)
            // this.games.push(items)
          })
          this.games = arr;
        }
        callback();
        this.currentGame = current;
        setTimeout(() => {
          this.scrollToBottom();
        }, 0)

        // this.addInterval(current.countdown, current);
        if (!flag) {
          this.addInterval(current.countdown, current, data.lists[1]);
        }

        Toast.clear();
      } catch (error) {
        Toast.clear();
      }
    },
    // 状态判断
    getStatus(item) {
      if (!item) return;
      const { status, open } = item;
      switch (status) {
        case "待开奖":
          return this.$t("status.waiting");
          break;
        case "已开奖":
          const data = open.dxds[0];
          // const nums = open.dwd;
          // for(let i=0,len=nums.length;i<len;i++){
          //   const i=0;
          // }
          let res = "";
          res = this.getStatusText(data.dx);
          res += "\n" + this.getStatusText(data.ds);
          return res;
          break;
        case "开奖出错":
          return this.$t("status.fail");
          break;
        default:
          return "";
          break;
      }
    },
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
          break;
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.single");
        case "双":
          return this.$t("open.double");
        case "龙":
          return this.$t("open.loong");
        case "虎":
          return this.$t("open.hu");
        default:
          return text;
      }
    },
    getStatusTexts(text) {
      return text.split("")
      // switch (text) {
      //   case "大":
      //     return this.$t("open.big");
      //     break;
      //   case "小":
      //     return this.$t("open.small");
      //   case "单":
      //     return this.$t("open.single");
      //   case "双":
      //     return this.$t("open.double");
      //   default:
      //     return text;
      // }
    },
    // 选择
    select({ value }) {
      const b = this.selectArr.find((a) => a == value);
      if (!b) {
        this.selectArr.push(value);
      } else {
        const newArr = [...this.selectArr];
        this.selectArr = newArr.filter((item) => item !== value);
      }
    },
    numSelect({ title }) {
      const b = this.numSelectArr.find((a) => a == title);
      if (!b) {
        this.numSelectArr.push(title);
      } else {
        const newArr = [...this.numSelectArr];
        this.numSelectArr = newArr.filter((item) => item !== title);
      }
    },
    txffcSelect({ value }, index) {
      let itemData = this.txffcSelectData[index];
      if (itemData) {
        const b = itemData.find((a) => a == value);
        if (!b) {
          itemData.push(value);
        } else {
          const newArr = [...itemData];
          itemData = newArr.filter((item) => item !== value);
        }
      } else {
        itemData = [value];
      }
      this.$set(this.txffcSelectData, index, itemData);
    },
    // 获取用户信息
    getUserInfo() {
      this.$api.getUserInfo((res) => {
        this.userInfo = res;
        this.userInfoMoney = res.money
      });
    },
    reset() {
      this.perPrice = "";
      this.selectArr = [];
      this.numSelectArr = [];
      this.newDxdsCode = ['', '', '', '', '']
      this.PkArr = [];
      this.pkResult = {};
      this.isShowFooter = false;
      this.pkHlDxdsArr = [new Array(10).fill(''), new Array(3).fill('')];
      this.$refs.pkRef && this.$refs.pkRef.clear();
      this.x5dxdsNum = 0;
      this.X5Arr = [];
      this.$refs.x5Ref && this.$refs.x5Ref.clear();
      this.roomList = {}
      this.$refs.roomRef && this.$refs.roomRef.close();
      this.activeLabel = 0
    },
    clear() {
      this.perPrice = "";
      this.selectArr = [];
      this.numSelectArr = [];
      this.newDxdsCode = ['', '', '', '', '']
      this.PkArr = [];
      this.pkResult = {};
      this.isShowFooter = false;
      this.pkHlDxdsArr = [new Array(10).fill(''), new Array(3).fill('')];
      this.$refs.pkRef && this.$refs.pkRef.clear();
      this.x5dxdsNum = 0;
      this.X5Arr = [];
      this.$refs.x5Ref && this.$refs.x5Ref.clear();
      this.gameShow = false;
      this.roomList = {}
      this.$refs.roomRef && this.$refs.roomRef.close();
      this.activeLabel = 0;
    },
    toConfirmMatch() {
      if (!this.showInput && !this.getNewDxdsLength() && !this.isShowFooter) {
        this.$toast(this.$t("hint.toAddOrder"));
        return;
      }
      if (this.perPrice == "") {
        this.$toast(this.$t("hint.toPerPrice"));
        return;
      }
      if (!this.countTime) {
        this.$toast(this.$t("hint.opening"));
        return;
      }
      this.toMatch();
      // this.buyShow = true;
    },
    async toMatch() {
      let promiseArr = [];
      if (JSON.stringify(this.roomList) != '{}') {
        if (Number(this.userInfo.money) < this.roomList) return this.$toast(this.$t("hint.moneyLess"));
        if (!this.countTime) return this.$toast(this.$t("hint.opening"));
        promiseArr.push(
          post("/lottery/send", {
            type: this.type,
            expect: this.currentGame.expect,
            method: this.roomList.lottery.method,
            code: this.roomList.lottery.code,
            money: (this.roomList.lottery.totalMoney - 0) / (this.roomList.lottery.number - 0),
            mtype: "yuan",
          })
        );
      } else if (this.category == 'pk') {
        if (Number(this.userInfo.money) < this.pkHlDxdsNum * (this.perPrice || 0)) return this.$toast(this.$t("hint.moneyLess"));
        if (!this.countTime) return this.$toast(this.$t("hint.opening"));
        const firstNum = this.pkHlDxdsArr[0].reduce((sum, item) => sum + item.length, 0);
        const secondNum = this.pkHlDxdsArr[1].reduce((sum, item) => sum + item.length, 0);
        if (firstNum) {
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "dxds",
              code: this.pkHlDxdsArr[0].join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        if (secondNum) {
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "lh",
              code: this.pkHlDxdsArr[1].join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
      } else if (this.category == 'x5') {
        if (Number(this.userInfo.money) < this.x5dxdsNum * (this.perPrice || 0)) return this.$toast(this.$t("hint.moneyLess"));
        if (!this.countTime) return this.$toast(this.$t("hint.opening"));
        if (this.x5dxdsNum) {
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              expect: this.currentGame.expect,
              method: "dwd",
              code: this.X5Arr.join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          )
        }
      } else {
        if (!([...this.selectArr, ...this.numSelectArr].length + this.txffcMatchNum + this.getNewDxdsLength()) || this.perPrice == "") return;
        if (Number(this.userInfo.money) < (this.matchAmount + this.getNewDxdsLength()) * (this.perPrice || 0)) {
          this.$toast(this.$t("hint.moneyLess"));
        }
        if (!this.countTime) {
          this.$toast(this.$t("hint.opening"));
          return;
        }
        let txffcMatchStr = "";
        if (this.txffcMatchNum) {
          const arr = [];
          for (let i = 0, len = this.txffcGames.length; i < len; i++) {
            arr.push(this.txffcSelectData[i] || []);
          }
          let num = 0;
          txffcMatchStr = arr.reduce((prev, current) => {
            if (num == 0) {
              num++;
              return current.join("");
            }
            return prev + `,${current.join("")}`;
          }, "");
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "dxds",
              code: txffcMatchStr,
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        if (!!this.getNewDxdsLength()) {
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              expect: this.currentGame.expect,
              method: 'dxds',
              code: this.newDxdsCode.join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        // if (!!this.selectArr.length) {
        //   promiseArr.push(
        //     post("/lottery/send", {
        //       type: this.type,
        //       // expect: this.orderLists[0].expect,
        //       expect: this.currentGame.expect,
        //       method: 'hz-hz-dxds',
        //       code: this.selectArr.join(''),
        //       money: this.perPrice,
        //       mtype: "yuan",
        //     })
        //   );
        // }
        for (let i = 0, len = this.selectArr.length; i < len; i++) {
          const items = this.selectArr[i]
          const obj = this.dxdsArr.find(item => item.title == items);
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              expect: this.currentGame.expect,
              method: obj.method,
              code: items,
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        for (let i = 0, len = this.numSelectArr.length; i < len; i++) {
          const item = this.numSelectArr[i];
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              expect: this.currentGame.expect,
              method: "hz-hz-" + item,
              code: item,
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
      }
      Promise.all(promiseArr).then((res) => {
        this.buyShow = false;
        this.clear();
        this.$toast(this.$t("hint.matchSuccess"));
        this.getUserInfo();
        this.$nextTick(() => {
          this.$refs.roomRef.clear();
          this.$refs.roomRef.getHistoryMessage();
        })
      }).catch((err) => {
        // this.$toast(this.$t("hint.matchFail"));
        this.$toast(err.msg || this.$t("hint.matchFail"))
      });
    },
    addInterval(countTime, item, item1) {
      if (!Number(countTime)) {
        this.countText = this.$t("status.open");
        this.addTime = setTimeout(() => {
          this.getOrders();
          this.num = 0
        }, (item.opentime - item.endtime) * 1000);
        return;
      }
      if (Number(countTime) && item1.status == '待开奖') {
        this.timerss = setTimeout(() => {
          this.getOrders();
        }, (item.opentime - item.endtime) * 1000);
        return;
      }

      this.countTime = countTime;
      this.intervalFun();
      this.interval = setInterval(() => {
        this.countTime--;
        this.intervalFun();
      }, 1000);
    },
    format(num) {
      if (num >= 10) return num;
      else return "0" + num;
    },
    intervalFun() {
      const hourUnit = 60 * 60;
      const minuteUnit = 60;
      const secondUnit = 1;
      const targetH = Math.floor(this.countTime / hourUnit);
      const targetM = Math.floor((this.countTime % hourUnit) / minuteUnit);
      const targetS = Math.floor(
        ((this.countTime % hourUnit) % minuteUnit) / secondUnit
      );
      if (!this.countTime) {
        clearInterval(this.interval);
        this.getOrders();
        this.getRoomData();
      }
      this.countText = `${this.format(targetH)}:${this.format(
        targetM
      )}:${this.format(targetS)}`;
    },
    stopTimer() {
      this.countTime = 0;
      this.countText = '00:00:00';
      clearInterval(this.interval);
      clearTimeout(this.timer);
      clearTimeout(this.addTime);
      clearTimeout(this.timerss);
      this.timer = null;
      this.interval = null;
      this.addTime = null;
      this.timerss = null;
    }
  },
  beforeDestroy() {
    this.stopTimer()
    this.$refs.roomRef.clear();
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  @import url("@/assets/iconfonts/iconfont.css");
  // @bg: #e4f0fa;
  @bg: var(--bg);
  @bgBlod: var(--navTheme);
  @black: var(--navTheme);
  @activeColor: #fea931;
  min-height: 100vh;
  background-color: @bg;
  display: flex;
  flex-direction: column;

  .nav_tools_item {
    margin: 0 6px;
    color: var(--light);
    font-size: 20px;

    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  .flex(@position: "") {
    display: flex;
    flex-direction: @position;
  }

  .ver(@position: center) {
    align-items: @position;
  }

  .hor(@position: center) {
    justify-content: @position;
  }

  .nav_left_wrap {
    display: flex;
    align-items: center;

    >.left_title {
      color: var(--textColor);
      margin-left: 5px;
    }
  }

  .right_popup_wrap {
    @topHeight: 46px;

    /deep/ .van-overlay {
      height: clac(100vh - @topHeight);
      top: @topHeight;
    }

    /deep/ .van-popup--top {
      top: @topHeight;
    }

    /deep/ .van-popup {
      top: @topHeight;
      background-color: transparent;
    }

    .tool_wrap {
      position: fixed;
      right: calc(10rem / 16);
      padding: 0 calc(10rem / 16);
      background-color: #fff;
      border-radius: calc(6rem / 16);
      z-index: 999;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: calc(10rem / 16) solid transparent;
        border-bottom-color: #fff;
        top: calc(-20rem / 16);
        right: calc(5rem / 16);
      }

      .tool_item {
        @height: calc(42rem / 16);
        // width: calc(100rem / 16);
        height: @height;
        line-height: @height;
        text-align: center;
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .top_wrap {
    padding: calc(10rem / 16) calc(10rem / 16) 0;
    display: flex;
    background: @bgBlod;

    >div {
      flex: 0 0 50%;
      text-align: center;
    }

    .left {
      .text {
        color: var(--red);
        font-size: calc(14rem / 16);
      }

      .time {
        color: @activeColor;
        font-size: calc(28rem / 16);
        line-height: calc(36rem / 16);
      }

      .banlace {
        display: block;
        color: #0d8ea7;
        font-size: calc(17rem / 16);
        font-weight: 700;
        margin-top: calc(2rem / 16);
        text-align: center;
      }
    }

    .right {
      .text {
        font-size: calc(14rem / 16);
        color: var(--textColor);

        span {
          color: var(--textColor);
        }
      }

      .status {
        font-size: calc(12rem / 16);

        color: @activeColor;
        display: flex;
        justify-content: center;
        margin-top: 6px;
        align-items: center;
        flex-wrap: wrap;

        >img {
          width: calc(18rem / 16);
          height: calc(18rem / 16);
          margin-left: 10px;

          &:nth-of-type(1) {
            margin-left: 0;
          }
        }

        .ball_wrap {
          .ball {
            border-radius: 50%;
            width: calc(26rem / 16);
            line-height: calc(26rem / 16);
            margin: 1px;
            background: var(--theme);
            color: #000;
            display: inline-block;
            background-image: url("../../../assets/icon/game_ball.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .sum_ball {
          border-radius: 50%;
          margin: 1px;
          margin-top: calc(5rem / 16);

          >span {
            margin-left: calc(8rem / 16);

            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }

        .icon-xiangxia {
          font-size: 16px;
          color: #f9982e;
          margin-left: 20px;
        }
      }

      .txffc {
        flex-direction: column;
      }
    }
  }

  .banlace {
    display: block;
    color: #0d8ea7;
    font-size: calc(17rem / 16);
    font-weight: 700;
    margin-top: calc(2rem / 16);
    text-align: center;
  }

  .top_icon {
    display: flex;
    justify-content: center;
  }

  .game_desc {
    padding: calc(10rem / 16) calc(20rem / 16) calc(5rem / 16) calc(20rem / 16);
    color: var(--gray1);
    font-size: calc(12rem / 16);
    text-align: center;
  }

  .content_wrap {
    // padding: 0 calc(20rem / 16);
    padding-bottom: calc(186rem / 16);

    .type_title {
      color: #999;
      text-align: center;
      margin-top: calc(16rem / 16);
    }

    .newGame {
      .all_title {
        white-space: nowrap;
        text-align: center;
        color: #999;
        padding: calc(16rem / 16) 0;
      }

      .gameLists {
        .list_title {
          white-space: nowrap;
          text-align: center;
          border-radius: 10px;
          color: var(--textColor);
          padding: calc(16rem / 16) 0;
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: calc(5rem / 16);

          &_value {
            border-radius: 4px;
            background: var(--light);
            font-size: calc(18rem / 16);
            width: calc((100% - (15rem / 16)) / 4);
            display: flex;
            align-items: center;
            flex-direction: column;
            color: var(--textColor);
            padding: calc(10rem / 16) 0;

            .value {
              font-size: calc(10rem / 16);
              word-break: break-all;
              text-overflow: ellipsis;
              margin-top: calc(3rem / 16);
              color: var(--gray1);
            }
          }

          .list_active {
            background-color: #f60;
            color: var(--light);

            .value {
              color: var(--light);
            }
          }
        }
      }
    }

    .select_wrap {
      // margin-top: calc(15rem / 16);
      display: flex;
      flex-wrap: wrap;

      &_left {
        width: 20%;
        max-width: calc(145rem / 16);

        .lable_item {
          display: block;
          text-align: center;
          line-height: calc(50rem / 16);
          background-color: #f7f7f7;
          border-top: solid 1px #cdcdcd;
          font-size: calc(16rem / 16);
          color: var(--gray3);
        }

        .active {
          background-color: #f60;
          color: var(--light);
        }
      }

      &_right {
        flex: 1;

        .label_right_title {
          text-align: center;
          color: #cb0816;
          background-color: #dedede;
          line-height: calc(30rem / 16);
          height: calc(30rem / 16);
          font-size: calc(16rem / 16);
        }

        .rights {
          display: flex;
          flex-wrap: wrap;

          .select_item {
            @height: calc(40rem / 16);
            background: var(--light);
            font-size: calc(15rem / 16);
            width: calc((100% - 1px) / 2);
            display: flex;
            align-items: center;
            color: var(--textColor);
            height: @height;
            line-height: @height;
            border-right: solid 1px #dedede;
            border-bottom: solid 1px #dedede;

            >p {
              flex: 1;
              display: flex;
              justify-content: center;

              &:nth-last-child(1) {
                font-size: calc(12rem / 16);
                word-break: break-all;
                text-overflow: ellipsis;
                color: var(--gray1);
              }
            }

            .number {
              width: calc(24rem / 16);
              height: calc(24rem / 16);
              border-radius: 50%;
              background-color: #dedede;
              font-weight: 700;
              font-size: calc(12rem / 16);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .select_item:nth-child(2n) {
            border-right: none;
          }

          .active {
            background-color: #f60;
            color: var(--light);

            >p {
              .number {
                background-color: var(--light);
                color: var(--textColor);
              }

              &:nth-last-child(1) {
                color: var(--light);
              }
            }

          }
        }

        .special_code {
          .select_item {
            width: calc((100% - 3px) / 3);
          }

          .select_item:nth-child(2n) {
            border-right: solid 1px #dedede;
          }

          .select_item:nth-child(3n) {
            border-right: none;
          }

          .select_item:nth-last-child(-n+4) {
            width: calc((100% - 2px) / 2);

            &:nth-child(2n) {
              border-right: none;
            }

            &:nth-child(3n) {
              border-right: solid 1px #dedede;
            }
          }
        }
      }
    }

    .img_wrap {
      @item_gap: calc(16rem / 16);
      @img_size: calc();
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: calc(18rem / 16);

      .img_item {
        @size: calc((100% - @item_gap * 3) / 4 - 2px);
        width: @size;
        height: @size;
        margin-right: @item_gap;
        margin-bottom: @item_gap;
        border-radius: 4px;

        // border: 1px solid #f1c8c8;
        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer {
    @height: calc(50rem / 16);
    @border: 1px solid #cdcdcd4d;
    position: fixed;
    bottom: calc(50rem / 16);
    width: 100vw;
    // background-color: var(--light);

    .input_wrap {
      border-top: @border;
      display: flex;
      align-items: center;
      padding: calc(5rem / 16) 0;
      @height: calc(26rem / 16);
      justify-content: space-between;

      >div {
        width: 50%;
        height: @height;
        display: flex;
        align-items: center;
      }

      .left {
        @left: calc(15rem / 16);
        padding-left: @left;
        flex-grow: 1;
        font-size: calc(14rem / 16);
        display: flex;
        align-items: center;
        color: var(--textColor);

        >input {
          text-indent: calc(5rem / 16);
          width: calc(120rem / 16);
          margin: 0 calc(12rem / 16);
          height: calc(@height - 2px);
          border-radius: 2px;
          border: 1px solid var(--textColor);
          background: transparent;
          color: var(--textColor);
        }
      }

      .clear {
        width: calc(58rem / 16);
        height: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid var(--borderColor);
        color: var(--red);
      }
    }

    .bottom {
      @height: calc(50rem / 16);
      display: flex;
      // border-top: @border;
      height: @height;
      width: 100vw;
      align-items: center;
      justify-content: space-between;
      // background: @black;
      background: var(--light);
      border-top: 1px solid #cdcdcd4d;

      .right {
        font-size: calc(14rem / 16);
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-left: calc(16rem / 16);
        color: var(--textColor);

        >.price {
          // color: #d80b2a;
          color: var(--red);
          margin-left: calc(6rem / 16);
        }
      }

      .buy {
        width: calc(120rem / 16);
        height: 100%;
        line-height: @height;
        text-align: center;
        background: var(--red);
        color: var(--light);
        font-weight: bold;
      }
    }

    &_pay {
      position: relative;
      background-color: #f60;
      padding: calc(10rem / 16);
      border-radius: calc(20rem / 16) calc(20rem / 16) 0 0;

      &_title {
        font-size: calc(14rem / 16);
        color: var(--light);
      }

      &_num {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: calc(10rem / 16);
        flex-wrap: wrap;

        .nums {
          background-color: #927402;
          width: calc(40rem / 16);
          height: calc(40rem / 16);
          border-radius: 50%;
          margin: 1px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--light);
          font-size: calc(14rem / 16);
        }
      }

      &_money {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: calc(5rem / 16);
        color: var(--light);
        font-size: calc(14rem / 16);

        .money_title {
          text-wrap: nowrap !important;
          white-space: nowrap !important;
        }

        .money_input {
          width: fit-content;
          background-color: var(--light);
          border-radius: calc(50rem / 16);
          padding: 0 calc(10rem / 16);

          .input {
            height: 30px;
            border: none;
            padding: 0;
            background-color: transparent;
            color: var(--textColor);
          }
            /deep/ .van-cell{
            .van-field__control{
              line-height: 30px;
            }
            }
        }

        .buy,
        .reset {
          text-wrap: nowrap !important;
          white-space: nowrap !important;
          font-size: calc(15rem / 16);
          line-height: calc(28rem / 16);
          padding: 0 calc(18rem / 16);
          border-radius: calc(50rem / 16);
          background: #470283;
        }

        .reset {
          background-color: #cb0816;
          padding: 0 calc(12rem / 16);
        }
      }

      .title {
        position: absolute;
        top: calc(-12rem / 16);
        left: 50%;
        transform: translateX(-50%);
        line-height: calc(24rem / 16);
        background-color: #cb0816;
        padding: 0 calc(20rem / 16);
        font-size: calc(14rem / 16);
        color: var(--light);
        border-radius: calc(50rem / 16);
      }
    }
  }

  .buy_pop {
    @title_height: calc(50rem / 16);
    background: var(--light);

    .titles {
      @height: @title_height;
      line-height: @height;
      height: @height;
      font-size: calc(18rem / 16);
      text-align: center;
      background: var(--red);
      color: var(--light);
    }

    .title {
      @height: @title_height;
      line-height: @height;
      height: @height;
      font-size: calc(18rem / 16);
      text-align: center;
      color: var(--textColor);
    }

    /deep/ .van-popup__close-icon {
      color: var(--light);
    }

    .content {
      padding: calc(12rem / 16) 0;
      margin: 0 calc(24rem / 16);
      border-bottom: 1px solid #e5e5e5;

      .li {
        display: flex;
        color: var(--textColor);
        font-size: calc(14rem / 16);
        padding: calc(6rem / 16);

        >div {
          .flex();
          // .ver(flex-end);
          flex-wrap: wrap;
        }

        .left {
          // min-width: calc(90rem / 16);
          white-space: nowrap;
        }

        .right {
          margin-left: calc(6rem / 16);

          .right_new {
            display: flex;
            margin-bottom: calc(5rem / 16);

            .number {
              width: calc(16rem / 16);
              height: calc(16rem / 16);
              border-radius: 50%;
              border: 1px solid var(--textColor);
              color: var(--textColor);
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: calc(16rem / 16);
            }

            margin-left: calc(5rem / 16);

            .new_list:nth-last-of-type(1) {
              .icon {
                display: none;
              }
            }
          }
        }

        .rights {
          >span {
            display: flex;
          }

          .right_new {
            display: ruby;
            padding-right: calc(10rem / 16);
            padding-bottom: calc(2rem / 16);
          }

          flex-wrap: wrap;
        }
      }
    }

    .hint {
      padding: calc(12rem / 16);
      text-align: center;
      color: var(--gray1);
    }

    .btn_wrap {
      padding: calc(12rem / 16);
      margin-bottom: calc(12rem / 16);

      .btn {
        color: var(--light);
        background: var(--red);
        font-size: calc(20rem / 16);
        text-align: center;
        line-height: calc(34rem / 16);
        padding: calc(10rem / 16) 0;
        border-radius: calc(6rem / 16);
      }
    }

    .sticky {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 999;

      .title {
        position: relative;

        .icon {
          position: absolute;
          height: @title_height;
          .flex();
          .ver(center);
          top: 0;
          left: 16px;
        }
      }
    }

    .title_bar {
      .flex();
      border-bottom: 1px solid #eee;

      .li {
        width: calc(100% / 3);
        text-align: center;
        line-height: calc(36rem / 16);
        color: #999;
      }
    }

    .list_wrap {
      position: relative;

      .li_wrap {
        --big: #f9982e;
        --small: #7f8ab0;
        .flex();

        .li {
          width: calc(100% / 3);
          line-height: calc(36rem / 16);
          font-size: calc(12rem / 16);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          >img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border: 1px solid #eee;

            &:nth-child(1) {
              margin-left: 46px;
            }
          }

          &:nth-child(1) {
            color: #666;
          }
        }

        &:nth-child(2n) {
          background-color: #eee;
        }
      }
    }
  }

  .game_pop {
    height: var(--height);

    .overlays {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: calc(51rem / 16);
      background-color: rgba(0, 0, 0, .3);
    }
  }

  .room {
    flex-grow: 1;
    background-color: var(--light);
  }

  .room_btn {
    position: fixed;
    z-index: 10;
    width: 100%;
    bottom: 0;
    background-color: var(--light);
    height: calc(50rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #cdcdcd;

    .btn {
      border-radius: calc(50rem / 16);
      background-color: #f60;
      width: calc(80%);
      height: calc(40rem / 16);
      text-align: center;
      line-height: calc(40rem / 16);
      color: var(--light);
      font-size: calc(15rem / 16);
    }
  }

  .how_play {
    position: absolute;
    z-index: 99;
    bottom: calc(2rem / 16);
    width: calc(16rem / 16);
    padding: calc(4rem / 16) 0;
    left: 2px;
    word-wrap: break-word;
    font-size: calc(12rem / 16);
    background-color: #09a65d;
    color: var(--light);
    border-radius: calc(12rem / 16);
    text-align: center;
  }

  .game_pop {
    background-color: #efefef;
  }

  /deep/ .van-dialog {
    .van-dialog__header {
      padding: calc(12rem / 16);
      color: var(--textColor);
    }

    .dialog_cont {
      background-color: #efefef;
      max-height: 60vh;
      overflow-y: auto;
      padding: calc(10rem / 16);
      font-size: calc(14rem / 16);
      color: var(--gray3);

      h5 {
        line-height: calc(40rem / 16);
        margin: 0;
        font-size: calc(14rem / 16);
      }

      p {
        padding: calc(5rem / 16) 0;
        line-height: calc(18rem / 16);
        font-size: calc(14rem / 16);
      }
    }
  }
}
</style>